<template>
  <div class="w-full">
    <div class="w-full bg-white rounded overflow-hidden">
      <div class="flex flex-col">
        <h3 class="bg-gray-100 px-2 py-1 border-b border-gray-300 text-sm font-semibold">
          Kontakte hochladen (.xslx)
        </h3>

        <div class="flex m-2 border border-gray-400 rounded bg-blue-50 p-2">
          <div class="w-24 p-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 512 512"
              viewBox="0 0 512 512"
            >
              <g>
                <path
                  d="m444.792 31.279c-4.073-.809-8.012 1.832-8.818 5.895-.807 4.063 1.832 8.011 5.895 8.818 31.944 6.347 55.131 34.635 55.131 67.263v98.81c0 37.821-30.77 68.59-68.59 68.59h-10.16c-4.143 0-7.5 3.358-7.5 7.5v39.12c0 8.025-5.265 12.864-10.191 14.716-4.931 1.854-12.084 1.679-17.374-4.365l-47.61-54.41c-1.425-1.627-3.481-2.561-5.645-2.561h-99.48c-37.82 0-68.59-30.769-68.59-68.59v-98.81c0-37.821 30.77-68.59 68.59-68.59h182.96c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-182.96c-46.092 0-83.59 37.498-83.59 83.59v40.71h-63.27c-46.092 0-83.59 37.498-83.59 83.59v83.81c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-83.81c0-37.821 30.77-68.59 68.59-68.59h63.271v43.1c0 46.092 37.498 83.59 83.59 83.59h96.076l23.613 26.985v13.725c0 37.821-30.77 68.59-68.59 68.59h-99.48c-2.163 0-4.22.934-5.645 2.561l-47.608 54.409c-5.293 6.044-12.442 6.218-17.373 4.365-4.929-1.852-10.194-6.694-10.194-14.725v-39.11c0-4.142-3.357-7.5-7.5-7.5h-10.16c-32.628 0-60.916-23.186-67.264-55.132-.809-4.063-4.758-6.701-8.818-5.895-4.062.807-6.701 4.755-5.895 8.818 7.738 38.943 42.214 67.208 81.977 67.208h2.66v31.61c0 12.927 7.817 24.219 19.916 28.766 3.588 1.348 7.281 2.003 10.927 2.003 8.648 0 17.02-3.686 23.012-10.53l45.369-51.849h96.076c44.978 0 81.754-35.713 83.506-80.269l6.842 7.819c5.991 6.844 14.36 10.53 23.011 10.529 3.646 0 7.341-.655 10.929-2.003 12.097-4.547 19.913-15.834 19.913-28.756v-31.62h2.66c46.092 0 83.59-37.498 83.59-83.59v-98.81c-.001-39.761-28.267-74.237-67.209-81.975z"
                />
                <path
                  d="m154.979 322.592v-.472c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v.472c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5z"
                />
                <path
                  d="m183.637 330.092c4.143 0 7.5-3.358 7.5-7.5v-.472c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v.472c0 4.142 3.357 7.5 7.5 7.5z"
                />
                <path
                  d="m225.163 322.592v-.472c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v.472c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5z"
                />
                <path
                  d="m336.929 184.367c10.214-1.608 19.552-6.501 26.835-14.172 9.03-9.511 13.628-21.976 12.947-35.1-1.28-24.64-21.621-44.331-46.309-44.828-23.973-.475-44.554 17.032-47.864 40.745-.302 2.17-.455 4.388-.455 6.592 0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5c0-1.514.105-3.036.312-4.521 2.26-16.19 16.346-28.148 32.705-27.819 16.863.34 30.758 13.785 31.631 30.609.466 8.975-2.675 17.496-8.845 23.994-6.174 6.502-14.504 10.083-23.457 10.083-4.143 0-7.5 3.358-7.5 7.5v22.808c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5z"
                />
                <path
                  d="m329.429 222.125c-4.143 0-7.5 3.358-7.5 7.5v.471c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-.471c0-4.142-3.358-7.5-7.5-7.5z"
                />
              </g>
            </svg>
          </div>
          <div class="w-full pl-6">
            <ul class="list-disc">
              <li>
                Erhalten Sie wertvolle Tipps im Supportbeitrag zum Kontaktimport
              </li>
              <li>
                Bitte halten Sie sich strikt an die
                <a href="#" @click.prevent="download" class="text-blue-800">Excel Vorlagen</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full flex flex-col">
          <div class="p-2">
            <h3>Datei</h3>
            <div class="w-full flex p-2 border rounded space-x-2 items-center">
              <button
                class="relative border border-gray-600 rounded-sm bg-gray-200 p-1 overflow-hidden"
              >
                <input
                  type="file"
                  class="absolute inset-0 z-10 opacity-0 cursor-pointer"
                  @change="e => (file = onFileChange(e).file)"
                />
                Datei auswählen
              </button>
              <p>{{ file ? file.name : 'Keine ausgewählt' }}</p>
            </div>
          </div>

          <div class="w-full p-2 bg-gray-300">
            <button class="flex items-center p-2 bg-gray-100" @click.prevent="upload">
              <i class="fa fa-upload mr-2"></i>
              Dateien importieren
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { importPersonal, downloadTemplate } from '@/services/personal'

export default {
  name: 'import-export',
  data() {
    return {
      file: null,
      breadcrumbs: {
        title: [
          { crumb: 'Allgemeine Einstellungen', link: '/settings' },
          { crumb: 'Import / Export', link: '/settings/import-export' },
          { crumb: 'Kontakte' },
        ],
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
    download() {
      downloadTemplate()
    },
    upload() {
      if (this.file)
        importPersonal({ file: this.file })
          .then(response => {
            if (response.status === 200) {
              // this.$toast.success(response.data.message)
              this.$router.push('/personal')
            } else {
              this.$toast.error('Fehler beim Datenimport')
            }
          })
          .catch(() => {
            this.$toast.error('Fehler beim Datenimport')
          })
    },
  },
}
</script>

<style></style>
